// AWS Amplify Configuration Template - autogenerated at deploy time
/* eslint no-template-curly-in-string: 0*/
const config = {
  s3: {
    REGION: "eu-central-1",
    BUCKET: "app-be-company-844375376115-uploads",
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://ryd9uvgcki.execute-api.eu-central-1.amazonaws.com/prod/v1",
  },
  cognito: {
    REGION: "eu-central-1",
    USER_POOL_ID: "eu-central-1_X3IvsbIIE",
    APP_CLIENT_ID: "eqqsj466dnmvbbhnacdiqm82",
    IDENTITY_POOL_ID: "eu-central-1:e763f379-8690-4a25-b323-e86af7a02226",
  },
  locales: {
    URL: "https://locales.platform.prod.beteiligungsboerse.net",
  },
  datastore: {
    URL: "https://datastore.platform.prod.beteiligungsboerse.net",
  },
  integrations: {
    CALENDLY_TOKEN: "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzI3MjcwMDA1LCJqdGkiOiJjNTFjMmNhMy1hNWY5LTRhYzAtODNhYS1lZGQ5NjI1OWVlODYiLCJ1c2VyX3V1aWQiOiJmYjU5MjExZi1kNjI2LTRiZTAtOTMxYy01NGMwOWM3OTBjNDAifQ.KNpH3huGgJoH7psXOSok8wunelzTFngTgmby6OgKZVSxjYPxEBTfsNNyfnEB10yeLiZ8vpPcGsJFcN24NggIRg",
    CALENDLY_URL: "https://calendly.com/kp-beteiligungsboerse/30min",
  },
  registration: {
    URL: "https://registration.platform.prod.beteiligungsboerse.net/v1",
    NEXT_PAGE: "https://app.company.prod.beteiligungsboerse.net",
    TO_REFERRER: "https://www.beteiligungsboerse.eu/register-success/",
  },
  product: {
    PRODUCT_KEY: "company",
  },
};

export default config;
